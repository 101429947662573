import React from 'react';
import { Steps } from 'antd';

const StepsComponent = ({ className = '', current = 0, items = [], ...props }) => {
    return (
        <Steps current={current} items={items} {...props}>
            {props?.children}
        </Steps>
    );
};

export default StepsComponent;
