// React-
import React from "react";
import ReactDOM from "react-dom/client";

// Third party-
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// CSS-
import "./index.css";

// Locale-
import "./utils/locales/config";

// App-
import App from "./App";

// Report-
import reportWebVitals from "./reportWebVitals";

// Redux-
import configureStore from "./state";

// ServiceWorkers
import { register } from "./utils/serviceWorkerUtils";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = configureStore();

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);

register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
