import { createSlice } from "@reduxjs/toolkit";

export const facilityReducer = createSlice({
    name: "facility",
    initialState: null,

    reducers: {
        setFacility: (state, action) => {
            // Update selected facility
            return action.payload;
        },
    },
});

// Action creator
export const { setFacility } = facilityReducer.actions;

export default facilityReducer.reducer;
