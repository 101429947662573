import React from 'react';
import dayjs from 'dayjs';
import SelectComponent from '../select/SelectComponent';
import { inMilliseconds } from '../../../utils/commonFunctions';

export const durationOptions = [
    { id: 11, text: 'Complete Run', value: '' },
    { id: 0, text: '1 minute', value: '1m' },
    { id: 1, text: '2 minutes', value: '2m' },
    { id: 2, text: '3 minutes', value: '3m' },
    { id: 3, text: '5 minutes', value: '5m' },
    { id: 4, text: '10 minutes', value: '10m' },
    { id: 5, text: '30 minutes', value: '30m' },
    { id: 6, text: '1 hour', value: '1h' },
    { id: 7, text: '2 hours', value: '2h' },
    { id: 8, text: '3 hours', value: '3h' },
    { id: 9, text: '6 hours', value: '6h' },
    { id: 10, text: '12 hours', value: '12h' },
];

export const getSelectedTimeDurationInMs = (record, selectedTimeDuration) => selectedTimeDuration ? inMilliseconds(selectedTimeDuration) : dayjs(record.timeEnd).diff(record.timeStart, 'ms');

const DurationDropdown = ({
    onTimeDurationChange,
    selectedTimeDuration,
    disabledDurationOptions,
}) => {
    return (
        <>
        <SelectComponent
            className='durationSelect'
            placeholder='Select Time Duration'
            filterSort={false}
            options={durationOptions}
            onChangeEvent={onTimeDurationChange}
            value={selectedTimeDuration?.text ?? '1 hour'}
            disabledOptions={disabledDurationOptions}
        />
        </>
    );
};

export default DurationDropdown;
