import { useLocation, useNavigate } from "react-router-dom";
import "./app.scss";
import ContentRoutes from "./layout/routes";
import { history } from "./utils/history";

import { ErrorBoundary } from "react-error-boundary"; // Error catch library-
import ErrorBoundaryFallback from "../src/layout/private-routes/error-boundary/index"; //This is the fallback component-

// custom hook-
import { useEffect } from "react";
import useSystemLanguage from "./hooks/useSystemLanguage";
import useApiClient from "./services/useApiClient";
import { requestNotificationPermission } from "./utils/notifications";
import { subscribeUser } from "./utils/subscribeUser";

function App() {
    const client = useApiClient();

    useEffect(() => {
        requestNotificationPermission();
        subscribeUser(
            client,
            "BBy6tzEEsY81XqvHK_GlXlngdnSRx1fyFigqk8ip5D6b3XDhWkXeA64P-eX8YjrUKx_06YR2Llr6To5e9CcQB5c"
        );
    }, []);
    // This needs to be updated to write the error to the backend-
    const errorHandler = (error, errorInfo) => {
        console.log("Logging: ", error, errorInfo);
    };

    history.location = useLocation();
    history.navigate = useNavigate();

    useSystemLanguage();

    return (
        <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onError={errorHandler}
        >
            <ContentRoutes />
        </ErrorBoundary>
    );
}

export default App;
