import React, { useState, useEffect } from 'react';
import FacilityCell from './FacilityCell';
import useApiClient from '../../../services';
import { selectSortFunction, cellAscendingSortFunction } from '../../../utils/commonFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectionActions } from '../../../state/features/userSelectionReducer';

const FacilityCellContainer = ({
    showCell = false,
    onChangeEvent = () => {},
    clearStateFunction = () => {},
    currentMenu,
}) => {
    // Set the state on the facility
    const [selectedFacility, setSelectedFacility] = useState();

    // Set the state on the cell
    const [selectedCell, setSelectedCell] = useState();

    const [facilityList, setFacilityList] = useState({ loading: true, data: [{ id: 'Loading', text: 'Loading' }] });
    const [cellList, setCellList] = useState({
        loading: true,
        data: [{ id: 'Select', text: 'Select a Facility' }],
    });
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const client = useApiClient();
    const dispatch = useDispatch();

    const userSelection = useSelector((x) => x?.userSelection);

    useEffect(() => {
        const obj =
            selectedFacility?.id == userSelection?.facility?.id && selectedCell?.id == userSelection?.cell?.id
                ? { product: userSelection?.product }
                : {};
        dispatch(
            userSelectionActions.setUserSelectionInfo({
                ...obj,
                facility: selectedFacility,
                cell: selectedCell,
            })
        );
    }, [selectedFacility, selectedCell]);

    // *** API CALLS *** //
    // API call to receive all the facilities
    const fetchFacilities = async () => {
        const masterFacility = await client.getAllFacilities();

        const result = masterFacility?.data?.length
            ? masterFacility?.data.map((facility) => {
                  return {
                      id: facility?.id,
                      text: `${facility?.facilityName}`,
                  };
              })
            : [];
        setFacilityList({ loading: false, data: result });
        if (isInitialLoad) {
            if (!userSelection?.facility?.id) {
                const sortedArray = result.sort((a, b) => selectSortFunction(a?.text, b?.text));
                //console.log("setFacilityList: ",sortedArray )
                fetchCells(sortedArray?.[1]);
            } else {
                fetchCells(userSelection?.facility);
            }
        }
    };

    useEffect(() => {
        fetchFacilities();
    }, []);

    // API call to receive the cells by facility id
    const fetchCells = async (facility) => {
        if (facility?.id) {
            const masterFacilityLine = await client.getFacilityCells(facility?.id);
            const result = masterFacilityLine?.data?.length
                ? masterFacilityLine?.data.map((cell) => {
                      return { id: cell?.id, text: cell?.name, facility_id: cell?.facility?.id };
                  })
                : [];

            const sortedArray = result.sort((a, b) => cellAscendingSortFunction(a?.text, b?.text));    
            setCellList({ loading: false, data: sortedArray });

            if (isInitialLoad) {
                const sortedArray = result.sort((a, b) => cellAscendingSortFunction(a?.text, b?.text));
                const initialCell = {
                    id: userSelection?.cell?.id ?? sortedArray?.[1]?.id,
                    text: userSelection?.cell?.text ?? sortedArray?.[1]?.text,
                };
                setIsInitialLoad(false);
                setSelectedFacility({ id: facility?.id, text: facility?.text });
                setSelectedCell({ id: initialCell?.id, text: initialCell?.text });
                onChangeEvent(facility?.id, initialCell?.id);
            }
        } else {
            setCellList({ loading: false, data: [{ id: 'Select', text: 'Select a Facility' }] });
        }
    };

    // Facility Handler: sets the state of the facility based on user selection
    const facilityHandler = (value, option) => {
        setCellList({ loading: true, data: [{ id: 'Loading', text: 'Loading' }] });
        setSelectedFacility({ text: value, id: option?.key });
        setSelectedCell();
        fetchCells({ text: value, id: option?.key });
        if (!option?.key) {
            onChangeEvent(null, null);
        }
        onChangeEvent(option?.key, null);
        if (!showCell) {
            onChangeEvent(option?.key, false);
        }
    };

    // Cell Handler: sets the state of the cell based on user selection
    const cellHandler = (_, option) => {
        setSelectedCell({ id: option?.key, text: option?.value });
        // This will clear the profile and table of data when this dropdown is changed
        onChangeEvent(selectedFacility?.id, option?.key);
    };

    // Clear Filter Handler: resets all the dropdowns back to default
    const clearDropdownHandler = () => {
        setSelectedFacility();
        setSelectedCell();
        onChangeEvent(null, null);
        setCellList({ loading: false, data: [{ id: 'Select', text: 'Select a Facility' }] });
    };

    clearStateFunction(clearDropdownHandler);

    // Facility Clear Handler: resets both the Cell and SKU Part Number dropdown when the facility dropdown is clicked
    const facilityClearHandler = () => {
        setSelectedCell();
    };

    return (
        <FacilityCell
            facilityClearHandler={facilityClearHandler}
            cellHandler={cellHandler}
            facilityHandler={facilityHandler}
            selectedFacility={selectedFacility}
            showCell={showCell}
            selectedCell={selectedCell}
            clearDropdownHandler={clearDropdownHandler}
            facilityList={facilityList}
            cellList={cellList}
            currentMenu={currentMenu}
        />
    );
};

export default FacilityCellContainer;
