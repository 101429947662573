import { useEffect } from "react";

// This hooks sets the browser's language as the default language for the app
// if it hasn't been set yet on the session storage on the first render
const useSystemLanguage = () => {
  useEffect(() => {
    if (!sessionStorage.getItem("appLanguage")) {
      // Get the browser's language
      const systemLanguage = navigator.language || navigator.userLanguage;
      sessionStorage.setItem("appLanguage", systemLanguage.split("-")[0]);
    }
  }, []);
};

export default useSystemLanguage;
