import React, { lazy } from "react";

const Operator = lazy(() => import("./index"));

const OperatorRoute = () => {
    return [
        {
            key: "operator",
            path: "/operator",
            component: <Operator />,
        },
    ];
};

export default OperatorRoute;
