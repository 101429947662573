import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useApiClient from '../../../services';
/**
 * Embeds a Grafana dashboard into a page.
 * See `defaultProps` for other parameters. */
export const GrafanaDashboard = ({ src, title, kioskMode = '1', height = '100%', width = '100%', style, className, refreshKey }) => {
    const token = useSelector(x => x?.auth?.user?.accessToken);
    const api = useApiClient();
    const [url, setURL] = useState("");
    useEffect(() => {

        // This is a bit of hack to prevent the user from using grafana keyboard shortcuts in the embedded view
        // it would be preferable to attach intercepting keypress event listeners but that required grafana on the same domain
        // solution from: https://github.com/grafana/grafana/issues/13706#issuecomment-1263109009
        const listener = window.addEventListener("blur", e => {
            setTimeout(function () {
                window.focus();
            }, 0);
        });
        const refreshInterval = 45 * 60 * 1000; // our auth tokens expire after 1 hour, so refresh every 45 minutes
        const refreshIntervalFunc = setInterval(async () => {
            const isExpired = api.checkSessionExpiry(token);
            if (isExpired) {
                await api.refreshToken();
            }

        }, refreshInterval);
        return () => {
            window.removeEventListener('blur', listener);
            clearInterval(refreshIntervalFunc);
        };
    }, []);


    useEffect(() => {
        //update url
        let tmpUrl = src;
        const origin = api.getOrigin();
        const isHostInUrl = tmpUrl.includes(origin);
        if (!isHostInUrl) {
            tmpUrl = origin + tmpUrl;
        }
        if (token) {
            if (!tmpUrl.includes("?")) {
                tmpUrl += `?&kiosk=${kioskMode}&auth_token=${token}`;
            } else {
                tmpUrl += `&kiosk=${kioskMode}&auth_token=${token}`;
            }
        }
        setURL(tmpUrl);
    }, [token, src]);

    
    // let url = src.replace('virglldatahubp1','virglldatahubp1.csaid.cooperintra.ctb');
    // if (!url.includes("?")) {
    //     url += `?&kiosk=${kioskMode}&auth_token=${token}`;
    // } else {
    //     url += `&kiosk=${kioskMode}&auth_token=${token}`;
    // }
    

    return (
        <iframe
            key={refreshKey}
            title={title}
            id={title}
            src={url}
            width={width}
            height={height}
            style={{ border: 'none', ...style }}
            className={className}
        />
    )
}

