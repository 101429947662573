import React, { lazy } from "react";

const AlertLog = lazy(() => import("./index"));

const AlertLogRoute = () => {
    return [
        {
            key: "alert-log",
            path: "/alert-log",
            component: <AlertLog />,
        },
    ];
};

export default AlertLogRoute;
