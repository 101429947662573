import { createSlice } from "@reduxjs/toolkit";

export const cellReducer = createSlice({
    name: "cell",
    initialState: null,
    reducers: {
        setCell: (state, action) => {
            // Update selected cell
            return action.payload;
        },
    },
});

// Action creator
export const { setCell } = cellReducer.actions;

export default cellReducer.reducer;
