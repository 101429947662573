import React, { lazy } from "react";

const OEEConsole = lazy(() => import("./index"));

const OEEConsoleRoutes = () => {
    return [
        {
            key: "oee-console",
            path: "/oee-console",
            component: <OEEConsole />,
        },
    ];
};

export default OEEConsoleRoutes;
