/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import FacilityCellPartDropdowns from "./FacilityCellPartDropdowns";
import { selectSortFunction, cellAscendingSortFunction } from "../../../utils/commonFunctions";
import useApiClient from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { userSelectionActions } from "../../../state/features/userSelectionReducer";

const FacilityCellPartDropdownsContainer = ({
  dropdownValuesHandler = () => {},
  titleMessage = "",
  bodyMessage = "",
  preload = false,
  isDisabled = false
}) => {
  // FcpDropdown Values for all three dropdowns (facility, cells, profiles)
  const [dropdownValues, setDropdownValues] = useState({
    facility: undefined,
    cell: undefined,
    profile: undefined,
  });

  // Toggle to return Active (true) or non-Active (false) products- Not set dynamically- Should not show non-Active parts in ML-
  const [isActive] = useState(true);

  // Allows all three dropdowns to be prepopulated if the user is using the "Show" Button- isClear will equal false-
  const [preloadData, setPreloadData] = useState(preload);

  const [facilityList, setFacilityList] = useState({
    loading: true,
    data: [{ id: "Loading", text: "Loading" }],
  });

  const [cellList, setCellList] = useState({
    loading: false,
    data: [{ id: "Select", text: "Select a Facility" }],
  });

  const [partNumberList, setPartNumberList] = useState({
    loading: false,
    data: [{ id: "Select", text: "Select a Cell" }],
  });

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const client = useApiClient();
  const dispatch = useDispatch();

  const userSelection = useSelector((x) => x?.userSelection);

  useEffect(() => {
    if (!isInitialLoad) {
      dropdownValuesHandler(dropdownValues);
      dispatch(
        userSelectionActions.setUserSelectionInfo({
          facility: dropdownValues?.facility,
          cell: dropdownValues?.cell,
          product: dropdownValues?.profile,
        })
      );
    }
  }, [dropdownValues]);

  // *** API CALLS *** //
  // API call to receive all the facilities
  const fetchFacilities = async () => {
    const masterFacility = await client.getAllFacilities();

    const result = masterFacility?.data?.length
      ? masterFacility?.data.map((facility) => {
          return {
            id: facility?.id,
            text: `${facility?.facilityName}`,
          };
        })
      : [];
    setFacilityList({ loading: false, data: result });
  };

  // API call to receive the cells by facility id
  const fetchCells = async (facilityId) => {
    if (facilityId) {
      const masterFacilityLine = await client.getFacilityCells(facilityId);
      const result = masterFacilityLine?.data?.length
        ? masterFacilityLine?.data.map((cell) => {
            return {
              id: cell?.id,
              text: cell?.name,
              facility_id: cell?.facility?.id,
            };
          })
        : [];

      const sortedArray = result.sort((a, b) => cellAscendingSortFunction(a?.text, b?.text)); 
      setCellList({ loading: false, data: sortedArray });

      return result;
    } else {
      setCellList({
        loading: false,
        data: [{ id: "Select", text: "Select a Facility" }],
      });
      return [];
    }
  };

  // API call to receive all the parts by cell id
  const fetchParts = async (cellId) => {
    // Make the first call to get the total number of pages-
    if (cellId) {
      const facilityLinesAssignedParts = await client.getCellParts(cellId, isActive, 1);
      const totalPages = Math.ceil(facilityLinesAssignedParts.meta.total / facilityLinesAssignedParts.meta.page_size);

      const fetchData = async (pageNumber, accumulatedData = []) => {
        if (pageNumber <= totalPages) {
          const facilityLinesAssignedParts = await client.getCellParts(
            cellId,
            isActive,
            pageNumber
          );

          const result = facilityLinesAssignedParts?.data?.length
            ? facilityLinesAssignedParts.data.map((profile) => {
                return {
                  id: profile?.product?.id,
                  text: profile?.product?.description,
                  facility_line_id: profile?.cellId,
                };
              })
            : [];

          // Create a variable to store all data returned from API call(s)-
          const newAccumulatedData = [...accumulatedData, ...result];

          // Continue to make API calls until all the data has been returned-
          if (pageNumber < totalPages) {
            await fetchData(pageNumber + 1, newAccumulatedData);
          } else {
              setPartNumberList({ loading: false, data: newAccumulatedData });
            }
        }
    };
    // Start fetching data from page number 1-
    await fetchData(1);
}
    return partNumberList.data;
  };

  useEffect(() => {
    fetchFacilities();
  }, []);

  useEffect(() => {
    if (
      preloadData &&
      facilityList?.data?.[0]?.id &&
      facilityList?.data?.[0]?.id !== "Loading"
    ) {
      getPreloadedData();
    }
  }, [facilityList]);

  // *** FUNCTIONS *** //
  // Facility Handler: Set the value of the facility in the dropdownValues variable
  const facilityHandler = (value, option) => {
    setCellList({ loading: true, data: [{ id: "Loading", text: "Loading" }] });
    if (option?.key) {
      fetchCells(option?.key);
    } else {
      setPartNumberList({
        loading: false,
        data: [{ id: "Select", text: "Select a Cell" }],
      });
      setCellList({
        loading: false,
        data: [{ id: "Select", text: "Select a Facility" }],
      });
    }
    setDropdownValues({
      facility: { text: value, id: option?.key },
      cell: undefined,
      profile: undefined,
    });
  };

  // Cell Handler: Set the value of the cell in the dropdownValues variable
  const cellHandler = (value, option) => {
    setPartNumberList({
      loading: true,
      data: [{ id: "Loading", text: "Loading" }],
    });
    fetchParts(option?.key);
    setDropdownValues({
      facility: dropdownValues?.facility,
      cell: option?.key ? { id: option?.key, text: value } : undefined,
      profile: undefined,
    });
  };

  // Profile Handler: Set the value of the profile in the dropdownValues variable
  const profileHandler = (value, option) => {
    setDropdownValues({
      facility: dropdownValues?.facility,
      cell: dropdownValues?.cell,
      profile: { id: option?.key, text: value },
    });
  };

  // Clear Filter Handler: Resets the dropdownValues when the button is clicked (is isClear is true; else do nothing)
  const clearDropdownHandler = () => {
    setDropdownValues({
      facility: undefined,
      cell: undefined,
      profile: undefined,
    });
    setCellList({
      loading: false,
      data: [{ id: "Select", text: "Select a Facility" }],
    });
    setPartNumberList({
      loading: false,
      data: [{ id: "Select", text: "Select a Cell" }],
    });
  };

  const getInitialCell = async (preloadedFacilityId) => {
    const cellList = await fetchCells(preloadedFacilityId);

    const sortedCell = cellList.sort((a, b) =>
      selectSortFunction(a?.text, b?.text)
    );
    return !userSelection?.cell?.id ? sortedCell?.[0] : userSelection?.cell;
  };

  const getInitialPart = async (preloadedCellId) => {
    const partList = await fetchParts(preloadedCellId);
    const sortedPartList = partList.sort((a, b) =>
      selectSortFunction(a?.text, b?.text)
    );
    return !userSelection?.product?.id
      ? sortedPartList?.[0]
      : userSelection?.product;
  };

  // Function to gather the preloaded data
  const getPreloadedData = async () => {
    setIsInitialLoad(false);
    // Get the first record id & facility_id in the master facility test data file-
    const sortedFacility = facilityList?.data.sort((a, b) =>
      selectSortFunction(a?.text, b?.text)
    );
    let initialFacility = {};
    if (!userSelection?.facility?.id) {
      initialFacility = { ...sortedFacility?.[0] };
      fetchCells(sortedFacility?.[0]?.id);
    } else {
      initialFacility = { ...userSelection?.facility };
      fetchCells(userSelection?.facility?.id);
    }

    // From the first id's for the facility; find the first cell name and id in the master facility line test data file-
    const initialCell = await getInitialCell(initialFacility?.id);

    // From the first cell id found; find the first profile from the facility line assigned parts test data file-
    const facilityLinesAssignedParts = await getInitialPart(initialCell?.id);

    const result = {
      facility: { text: initialFacility?.text, id: initialFacility?.id },
      cell: { text: initialCell?.text, id: initialCell?.id },
      profile: {
        text: facilityLinesAssignedParts?.text,
        id: facilityLinesAssignedParts?.id,
      },
    };
    // Set the three dropdowns to the preloaded data
    setDropdownValues(result);
    dispatch(userSelectionActions.setUserSelectionInfo(result));
  };

  return (
    <FacilityCellPartDropdowns
      titleMessage={titleMessage}
      bodyMessage={bodyMessage}
      facilityHandler={facilityHandler}
      dropdownValues={dropdownValues}
      facilityList={facilityList}
      cellHandler={cellHandler}
      cellList={cellList}
      profileHandler={profileHandler}
      partNumberList={partNumberList}
      clearDropdownHandler={clearDropdownHandler}
      isDisabled={isDisabled} // used to control the display of the dropdowns-
    />
  );
};

export default FacilityCellPartDropdownsContainer;
