import React from "react";
import "./facilityCell.scss";
import { useLocation } from "react-router-dom";
import {
  InformationPopUp,
  ConfigProviderComponent,
  Button,
  SelectComponent,
  antdTheme,
} from "../../shared";

const FacilityCell = ({
  cellHandler = () => {},
  facilityHandler = () => {},
  selectedFacility,
  showCell,
  selectedCell,
  clearDropdownHandler = () => {},
  facilityList,
  cellList,
  currentMenu,
}) => {
  const location = useLocation();
  const pathArr = location?.pathname?.split("/") ?? [];
  let path = pathArr?.[pathArr?.length - 1] || "";
  const currentTab = currentMenu?.keyPath?.[currentMenu?.keyPath?.length - 1];
  const tabSelection = currentMenu?.key;

  // Information Card Data
  const pathContent = {
    no_information: {
      title: "No Information",
      content: "No information was found on this item.",
    },
    live_services: {
      ml_controller: {
        ml_controller_dashboard: {
          title: "ML Controller - Dashboard",
          content: (
            <p>
              <b>Dashboard:</b> A metrics dashboard that shows key monitoring
              elements and relevant statistical metrics, designed to provide a
              real-time overview of the status and performance of AI Controller.
              <br />
              <br />
              <b>ML Controller:</b> Real time view of the ML based Digital
              Controls Platform in action. The trained AI-based controller
              continuously observes the state of the production process and
              issues new equipment setpoints as needed.
            </p>
          ),
        },
        ml_controller_charts: {
          title: "ML Controller - Charts",
          content: (
            <p>
              <b>Charts:</b> Time series plots where AI-recommended setpoints
              are displayed to show real-time effective process control. Key
              process signals are also displayed in live, along with upper and
              lower control limits if applicable.
              <br />
              <br />
              <b>ML Controller:</b> Real time view of the ML based Digital
              Controls Platform in action. The trained AI-based controller
              continuously observes the state of the production process and
              issues new equipment setpoints as needed.
            </p>
          ),
        },
        ml_controller_summary: {
          title: "ML Controller - Summary",
          content: (
            <p>
              <b>Summary:</b> A summary report that provides a concise and
              comprehensive overview of how well the ML Controller has performed
              over the entire production job based on key evaluation metrics.
              <br />
              <br />
              <b>ML Controller:</b> Real time view of the ML based Digital
              Controls Platform in action. The trained AI-based controller
              continuously observes the state of the production process and
              issues new equipment setpoints as needed.
            </p>
          ),
        },
      },
      ml_predictor: {
        ml_predictor_dashboard: {
          title: "ML Predictor - Dashboard",
          content: (
            <p>
              <b>Dashboard:</b> A metrics dashboard that shows key monitoring
              elements and relevant statistical metrics, designed to provide a
              real-time overview of the status and performance of ML Predictor.
              <br />
              <br />
              <b>ML Predictor:</b> Real time view of the ML based Digital
              Controls Platform in action. The trained ML-based predictor
              continuously observes the actual process data and uses advanced
              algorithms to forecast future values for selected signals.
            </p>
          ),
        },
        ml_predictor_charts: {
          title: "ML Predictor - Charts",
          content: (
            <p>
              <b>Charts:</b> Time series plots that show real-time Machine
              Leaning predictions for actual process signals.
              <br />
              <br />
              <b>ML Predictor:</b> Real time view of the ML based Digital
              Controls Platform in action. The trained ML-based predictor
              continuously observes the actual process data and uses advanced
              algorithms to forecast future values for selected signals.
            </p>
          ),
        },
        ml_predictor_summary: {
          title: "ML Predictor - Summary",
          content: (
            <p>
              <b>Summary:</b> A summary report that provides a concise and
              comprehensive overview of how well the ML Predictor has performed
              over the entire production job based on key evaluation metrics.
              <br />
              <br />
              <b>ML Predictor:</b> Real time view of the ML based Digital
              Controls Platform in action. The trained ML-based predictor
              continuously observes the actual process data and uses advanced
              algorithms to forecast future values for selected signals.
            </p>
          ),
        },
      },
      rules_controller: {
        rules_controller_dashboard: {
          title: "Rules Controller - Dashboard",
          content: (
            <p>
              <b>Dashboard:</b> A metrics dashboard that shows key monitoring
              elements and relevant statistical metrics, designed to provide a
              real-time overview of the status and performance of Rules
              Controller.
              <br />
              <br />
              <b>Rules Controller:</b> Real time view of the Rules based Digital
              Controls Platform in action. The advanced rules-based controller
              continuously observes the state of the production process and uses
              a set of rules to issue new equipment setpoints as needed.
            </p>
          ),
        },
        rules_controller_charts: {
          title: "Rules Controller - Charts",
          content: (
            <p>
              <b>Charts:</b> Time series plots where Rules-based recommended
              setpoints are displayed to show real-time effective process
              control. Key process signals are also displayed in live, along
              with upper and lower control limits if applicable.
              <br />
              <br />
              <b>Rules Controller:</b> Real time view of the Rules based Digital
              Controls Platform in action. The advanced rules-based controller
              continuously observes the state of the production process and uses
              a set of rules to issue new equipment setpoints as needed.
            </p>
          ),
        },
        rules_controller_summary: {
          title: "Rules Controller - Summary",
          content: (
            <p>
              <b>Summary:</b> A summary report that provides a concise and
              comprehensive overview of how well the Rules Controller has
              performed over the entire production job based on key metrics.
              <br />
              <br />
              <b>Rules Controller:</b> Real time view of the Rules based Digital
              Controls Platform in action. The advanced rules-based controller
              continuously observes the state of the production process and uses
              a set of rules to issue new equipment setpoints as needed.
            </p>
          ),
        },
      },
      training_wheels: {
        training_wheels_dashboard: {
          title: "Training Wheels - Dashboard",
          content: (
            <p>
              <b>Dashboard:</b> A metrics dashboard that provides a detailed
              analysis of how each type of controller influences the
              manufacturing process. It offers actionable insights to assist
              manufacturers in making informed decisions.
              <br />
              <br />
              <b>Training Wheels:</b> A service that compares performance
              between AI-based controller and Rules-based controller in
              real-time. It helps plant operators in selecting the most suitable
              process controller based on their manufacturing needs.
            </p>
          ),
        },
        training_wheels_charts: {
          title: "Training Wheels - Charts",
          content: (
            <p>
              <b>Charts:</b> Time series plots where both AI-based and
              Rules-based recommended setpoints are displayed to illustrate a
              side-by-side comparison.
              <br />
              <br />
              <b>Training Wheels:</b> A service that compares performance
              between AI-based controller and Rules-based controller in
              real-time. It helps plant operators in selecting the most suitable
              process controller based on their manufacturing needs.
            </p>
          ),
        },
        training_wheels_summary: {
          title: "Training Wheels - Summary",
          content: (
            <p>
              <b>Summary:</b> A summary report offering a brief yet thorough
              comparison between two controllers, assessing key evaluation
              metrics throughout the production period.
              <br />
              <br />
              <b>Training Wheels:</b> A service that compares performance
              between AI-based controller and Rules-based controller in
              real-time. It helps plant operators in selecting the most suitable
              process controller based on their manufacturing needs.
            </p>
          ),
        },
      },
      matrix_controller: {
        matrix_controller_dashboard: {
          title: "Matrix Controller - Dashboard",
          content: (
            <p>
              <b>Dashboard:</b> A metrics dashboard that shows key monitoring
              elements and relevant statistical metrics, designed to provide a
              real-time overview of the status and performance of Matrix
              Controller.
              <br />
              <br />
              <b>Matrix Controller:</b> Real time view of the Matrix based
              Digital Controls Platform in action. The advanced matrix-based
              controller continuously observes the state of the production
              process and uses a set of recipes to issue new equipment setpoints
              as needed.
            </p>
          ),
        },
        matrix_controller_output: {
          title: "Matrix Controller - Output",
          content: (
            <p>
              <b>Output:</b> Time series plots that show output signal value in
              real-time, comparing against targets and tolerances.
              <br />
              <br />
              <b>Matrix Controller:</b> Real time view of the Matrix based
              Digital Controls Platform in action. The advanced matrix-based
              controller continuously observes the state of the production
              process and uses a set of recipes to issue new equipment setpoints
              as needed.
            </p>
          ),
        },
        matrix_controller_equipment: {
          title: "Matrix Controller - Equipment",
          content: (
            <p>
              <b>Equipment:</b> Time series plots where Matrix-based recommended
              setpoints are displayed to show real-time effective process
              control. Key process signals are also displayed in live, along
              with upper and lower control limits if applicable.
              <br />
              <br />
              <b>Matrix Controller:</b> Real time view of the Matrix based
              Digital Controls Platform in action. The advanced matrix-based
              controller continuously observes the state of the production
              process and uses a set of recipes to issue new equipment setpoints
              as needed.
            </p>
          ),
        },
      },
    },
  };

  const currentTitleObj =
    path !== "live_services"
      ? pathContent?.["no_information"]
      : pathContent?.[path]?.[currentTab]?.[tabSelection];
  console.log("FacilityCell-currentTitleObj: ", currentTitleObj);
  const content = currentTitleObj?.content;
  const title = currentTitleObj?.title;

  return (
    <div>
      <ConfigProviderComponent theme={antdTheme}>
        <div className="fcDropdownContainer">
          <div className="fcItem">
            <div className="facilityLabel">Facility</div>
            <SelectComponent
              placeholder="Select a Facility"
              className="facilitySelect"
              onChangeEvent={facilityHandler}
              value={selectedFacility?.text}
              loading={facilityList?.loading ?? false}
              allowClear
              options={facilityList?.data ?? []}
              disabledOptions={["Loading"]}
            />
          </div>
          {showCell && (
            <>
              <div className="fcItem">
                <div className="cellLabel">Cell</div>
                <SelectComponent
                  placeholder="Select a Cell"
                  className="cellSelect"
                  onChangeEvent={cellHandler}
                  allowClear
                  value={selectedCell?.text}
                  loading={cellList?.loading ?? false}
                  options={cellList?.data ?? []}
                  disabledOptions={["Loading", "Select a Facility"]}
                  filterSort={false}
                />
              </div>
            </>
          )}
          <Button className="clearBtnFc" onClickEvent={clearDropdownHandler}>
            Clear
          </Button>
          <div className="infoIcon">
            <InformationPopUp title={title} content={content} />
          </div>
        </div>
      </ConfigProviderComponent>
    </div>
  );
};

export default FacilityCell;
