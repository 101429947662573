import { createSlice } from "@reduxjs/toolkit";

export const facilityListReducer = createSlice({
    name: "facilityList",
    initialState: [],

    reducers: {
        setFacilityList: (state, action) => {
            // Update Facility List
            return action.payload;
        },
    },
});

// Action creator
export const { setFacilityList } = facilityListReducer.actions;

export default facilityListReducer.reducer;
