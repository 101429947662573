import React from 'react';
import { Tabs } from 'antd';
import './tabs.scss';
//const { TabPane } = Tabs; // No longer used by Ant Design (deprecated)

const TabComponent = ({
    defaultActiveKey = '0',
    type = 'card',
    tabComponent = [],
    onTabClick = () => {},
    activeTab = 0,
    position = 'top',
}) => {
 
    return (
        <div>
            <Tabs
                tabPosition={position}
                defaultActiveKey={defaultActiveKey}
                type={type}
                onTabClick={onTabClick}
                activeKey={activeTab.toString()}
                items={tabComponent.map((tab, index) => {
                    const label = tab.tab;
                    const key = index.toString();
                    const children = tab.component;
                    return {
                        label:label,
                        key:key,
                        children:children
                    }
                })}
            >
            </Tabs>
        </div>
    );
};

export default TabComponent;
