import React from 'react';
import { Button, Form } from 'antd';
import './formFooter.scss';

const FormFooterComponent = ({ name, onCancel, className, disabled, okText='Ok' }) => {
    return (
        <Form.Item name={name} className={className}>
            <div>
                <Button onClick={onCancel} className='cancelFooterBtn'>Cancel</Button>
                <Button type='primary' htmlType='submit' className='okFooterBtn' disabled={disabled}>
                    {okText}
                </Button>
            </div>
        </Form.Item>
    );
};

export default FormFooterComponent;
