// Register the service worker
export async function register() {
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", async () => {
            try {
                const registration = await navigator.serviceWorker.register(
                    "/service-worker.js"
                );
                console.log(
                    "Service Worker registered with scope:",
                    registration.scope
                );
            } catch (error) {
                console.error("Service Worker registration failed:", error);
            }
        });
    }
}

// Unregister the service worker
export async function unregister() {
    if ("serviceWorker" in navigator) {
        try {
            const registration = await navigator.serviceWorker.ready;
            await registration.unregister();
            console.log("Service Worker unregistered");
        } catch (error) {
            console.error("Service Worker unregister failed:", error);
        }
    }
}
