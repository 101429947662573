import React, { lazy } from "react";

const ModelMetrics = lazy(() => import("./index"));

const ModelMetricsRoute = () => {
    return [
        {
            key: "model-metrics",
            path: "/model-metrics",
            component: <ModelMetrics />,
        },
    ];
};

export default ModelMetricsRoute;
