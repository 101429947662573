import React, { lazy } from "react";

const LiveLineDashboard = lazy(() => import("./index"));

const LiveLineDashboardRoute = () => {
    return [
        {
            key: "cell-dashboard",
            path: "/cell-dashboard",
            component: <LiveLineDashboard />,
        },
    ];
};

export default LiveLineDashboardRoute;