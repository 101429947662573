// React-
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// Routes-
import SplashScreen from "../components/shared/splash-screen/SplashScreenComponent";
import AdminRoute from "./content/admin/routes";
import ConfigurationRoute from "./content/configurations/routes";
import ControlsRoute from "./content/controls/routes";
import CustomerRoute from "./content/customers/routes";
import DashboardRoute from "./content/dashboard/routes";
import HistoricalRoute from "./content/historical/routes";
import LiveLineDashboardRoute from "./content/liveline-dashboard/routes";
import MachineLearningRoute from "./content/machine-learning/routes";
import ModelMetricsRoute from "./content/model-metrics/routes";
import AlertLogRoute from "./content/alert-log/routes";
import MonitorStackRoute from "./content/monitor-stack/routes";
import MonitorRoute from "./content/monitor/routes";
import OEEConsoleRoutes from "./content/oee-console/routes";
import OperatorRoute from "./content/operator/routes";
import PredictiveAlarmsRoute from "./content/predictive-alarms/routes";
import ReportingRoute from "./content/reporting/routes";
import ServerSummaryRoute from "./content/server-summary/routes";
import PrivateRoutes from "./private-routes";

const ContentRoutes = () => {
    const dashboardRoute = DashboardRoute();
    const livelineDashboardRoute = LiveLineDashboardRoute();
    const oeeConsoleRoutes = OEEConsoleRoutes();
    const modelMetrics = ModelMetricsRoute();
    const alertLog = AlertLogRoute();
    const operatorRoute = OperatorRoute();
    const adminRoute = AdminRoute();
    const monitorRoute = MonitorRoute();
    const customerRoute = CustomerRoute();
    const configurationRoute = ConfigurationRoute();
    const historicalRoute = HistoricalRoute();
    const serverSummaryRoute = ServerSummaryRoute();
    const controlsRoute = ControlsRoute();
    const monitorStackRoute = MonitorStackRoute();
    const reportingRoute = ReportingRoute();
    const predictiveAlarmsRoute = PredictiveAlarmsRoute();
    const machineLearningRoute = MachineLearningRoute();

    const routeObj = [
        ...dashboardRoute,
        ...livelineDashboardRoute,
        ...operatorRoute,
        ...adminRoute,
        ...monitorRoute,
        ...customerRoute,
        ...configurationRoute,
        ...historicalRoute,
        ...serverSummaryRoute,
        ...controlsRoute,
        ...monitorStackRoute,
        ...reportingRoute,
        ...predictiveAlarmsRoute,
        ...machineLearningRoute,
        ...oeeConsoleRoutes,
        ...modelMetrics,
        ...alertLog,
    ];

    return (
        <Routes>
            <Route path="/" element={<PrivateRoutes />}>
                {routeObj &&
                    routeObj.map((route) => {
                        return (
                            <Route
                                key={route?.key}
                                path={route?.path}
                                element={
                                    <Suspense fallback={<SplashScreen />}>
                                        {route?.component}
                                    </Suspense>
                                }
                            />
                        );
                    })}
            </Route>
            <Route key={"any"} path="*" element={<Navigate to={"/"} />} />
        </Routes>
    );
};

export default ContentRoutes;
