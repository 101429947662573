import backTest from "./icons/back-test-1.svg";
import cells from "./icons/cells-1.svg";
import configurationGreyIcon from "./icons/configuration-grey.svg";
import configurationYellow from "./icons/configuration-yellow.png";
import controls from "./icons/controls-admin-1.svg";
import customersGreyLogo from "./icons/customers-grey.svg";
import customersYellowLogo from "./icons/customers-yellow.png";
import dashboard from "./icons/dashboard-1.svg";
import documentation from "./icons/documentation-1.svg";
import DPK from "./icons/dpk-1.svg";
import EditYellowIcon from "./icons/edit-yellow.png";
import equipment from "./icons/equipment-1.svg";
import facilities from "./icons/facilities-1.svg";
import facilitiesYellowIcon from "./icons/facilities-yellow.png";
import admin from "./icons/general-admin-1.svg";
import ghostScheduler from "./icons/ghost-scheduler-1.svg";
import graphsAndCharts from "./icons/graphs-charts-1.svg";
import help from "./icons/help.svg";
import impactKPI from "./icons/impact-(kpi)-1.svg";
import info from "./icons/info.svg";
import LCPTrained from "./icons/lcp-1.svg";
import lcpPerformance from "./icons/lcp-performance.svg";
import liveHistoricalData from "./icons/live-hystorical-data-1.svg";
import LivelineOperatorLogo from "./icons/liveline-operator-logo.png";
import LivelineWhitelogo from "./icons/liveline-white-logo.png";
import Livelinelogo from "./icons/livelinelogo.png";
import logo from "./icons/logo.svg";
import LPP from "./icons/lpp-1.svg";
import MenutoogleIcon from "./icons/menu-yellow.svg";
import { default as MLAdmin, default as MLConfig } from "./icons/ml-1.svg";
import ML_Control_1 from "./icons/ml-control-1.svg";
import moduleAlarmIcon from "./icons/module-alarm.svg";
import moduleBasicPlatformIcon from "./icons/module-basic-platform.svg";
import moduleControlsIcon from "./icons/module-controls.svg";
import moduleReportingIcon from "./icons/module-reporting.svg";
import monitorLiveData from "./icons/monitor-live-data-old-1.svg";
import monitorML from "./icons/monitor-machine-learning-1.svg";
import monitorRules from "./icons/monitor-rules-1.svg";
import monitorStack from "./icons/monitor-stack-1.svg";
import predictiveAlarmConfiguration from "./icons/predictive-alarm-configuration-1.svg";
import predictiveAlarmsMonitor from "./icons/predictive-alarm-monitor-1.svg";
import predictiveAlarmsAdmin from "./icons/predictive-alarms-admin-1.svg";
import productSuiteIcon from "./icons/product-suite-20px.png";
import products from "./icons/products-1.svg";
import users from "./icons/user-light-2.svg";
import reporting from "./icons/reporting-1.svg";
import rulesAndConfiguration from "./icons/rules-configuration-1.svg";
import searchIcon from "./icons/search.png";
import serversummaryYellow from "./icons/server-summary-yellow.png";
import Signal_Data_New_1 from "./icons/signal-data-new-1.svg";
import serversummaryGrey from "./icons/surver-summary-grey.svg";
import userYellowIcon from "./icons/user-yellow.png";
import VML_1 from "./icons/vml-1.svg";

import report8827 from "./images/report-8827.png";
import report9092 from "./images/report-9092.png";
import reportRunHealth from "./images/report-runhealthsnapshot.png";

import cslogo2 from "./icons/cslogo2.png";
import jb_enterprises_1200px from "./icons/jb-enterprises-1200px.png";
import LivelineMobile from "./icons/liveline-fav.svg";
import LiveLine_technologies_logo from "./icons/liveline-technologies-logo.png";
import cpk_1 from "./images/demo-cpk-1.png";
import cpk_2 from "./images/demo-cpk-2.png";
import cpk_3 from "./images/demo-cpk-3.png";
import cpk_4 from "./images/demo-cpk-4.png";
import cpk_5 from "./images/demo-cpk-5.png";
import predictiveAlarms from "./images/predictive-alarms.png";
//import Liveline from './icons/liveline-white.svg';// Original Logo
import leftArrow from "./icons/leftarrow.svg";
import Liveline from "./icons/ll_icon_full_height_logo.svg"; // Updated Logo
import ProductSuiteImage from "./icons/product-suite.png";
import rightArrow from "./icons/right-arrow.svg";

import alarmModule from "./icons/alarm-module.png";
import basicPlatformModule from "./icons/basic-platform.png";
import contactUsIcon from "./icons/contact-us.svg";
import controlsModule from "./icons/controls-module.png";
import accordionDown from "./icons/down.svg";
import historicalRunsIcon from "./icons/historical-runs-02.svg";
import imageSlashRegularModule from "./icons/image-slash-regular.svg";
import LiveLineIcon from "./icons/liveline-icon-only.png";
import LiveLineTechLogo from "./icons/liveline-tech-logo.png";
import noImage from "./icons/no-image.svg";
import plug from "./icons/plug.svg";
import reportingModule from "./icons/reporting-module.png";
import TempIncrease from "./icons/temp-increase.svg";
import TempDecrease from "./icons/temp-decrease.svg";
import accordionUp from "./icons/up.svg";
import matrix from "./icons/matrix.svg";

const reportImages = {
    report8827,
    report9092,
    reportRunHealth,
    predictiveAlarms,
    cpk_1,
    cpk_2,
    cpk_3,
    cpk_4,
    cpk_5,
};

export {
    DPK,
    EditYellowIcon,
    LCPTrained,
    LPP,
    LiveLineIcon,
    LiveLineTechLogo,
    LiveLine_technologies_logo,
    Liveline,
    LivelineMobile,
    LivelineOperatorLogo,
    LivelineWhitelogo,
    Livelinelogo,
    MLAdmin,
    MLConfig,
    ML_Control_1,
    MenutoogleIcon,
    ProductSuiteImage,
    Signal_Data_New_1,
    TempIncrease,
    TempDecrease,
    VML_1,
    accordionDown,
    accordionUp,
    admin,
    alarmModule,
    backTest,
    basicPlatformModule,
    cells,
    configurationGreyIcon,
    configurationYellow,
    contactUsIcon,
    controls,
    controlsModule,
    cslogo2,
    customersGreyLogo,
    customersYellowLogo,
    dashboard,
    documentation,
    equipment,
    facilities,
    facilitiesYellowIcon,
    ghostScheduler,
    graphsAndCharts,
    help,
    historicalRunsIcon,
    imageSlashRegularModule,
    impactKPI,
    info,
    jb_enterprises_1200px,
    lcpPerformance,
    leftArrow,
    liveHistoricalData,
    logo,
    moduleAlarmIcon,
    moduleBasicPlatformIcon,
    moduleControlsIcon,
    moduleReportingIcon,
    monitorLiveData,
    monitorML,
    monitorRules,
    monitorStack,
    noImage,
    plug,
    predictiveAlarmConfiguration,
    predictiveAlarmsAdmin,
    predictiveAlarmsMonitor,
    productSuiteIcon,
    products,
    users,
    reportImages,
    reporting,
    reportingModule,
    rightArrow,
    rulesAndConfiguration,
    searchIcon,
    serversummaryGrey,
    serversummaryYellow,
    userYellowIcon,
    matrix,
};
